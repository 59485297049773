@import './variables';
@import './bioland.theme.scss';

html, body {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: #{$font};
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}