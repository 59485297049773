@charset "UTF-8";
/* stylelint-disable scss/operator-no-newline-after */
/* stylelint-disable indentation */
/* Utility for fetching a nested value from a typography config. */
/* stylelint-disable-next-line scss/at-function-pattern */
/* Gets the font size for a level inside a typography config. */
/* Gets the line height for a level inside a typography config. */
/* Gets the font weight for a level inside a typography config. */
/* Gets the letter spacing for a level inside a typography config. */
/* Gets the font-family from a typography config and removes the quotes around it. */
/* stylelint-disable-next-line max-line-length */
/* stylelint-disable-next-line scss/at-function-pattern */
/* stylelint-disable-next-line scss/at-mixin-pattern */
:root {
  --portal-slideshow-navigation-backround: transparent;
  --portal-slideshow-navigation-backround-hover: rgba(211, 230, 250, 0.25);
}

/* stylelint-disable declaration-no-important */
/* stylelint-disable-next-line scss/at-mixin-pattern */
/* stylelint-disable-next-line scss/at-mixin-pattern */
/* stylelint-disable-next-line scss/at-mixin-pattern */
/* Color config */
/* Font config */
html {
  --mat-badge-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 600 20px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 600 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(16px * 0.83) / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(16px * 0.67) / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 400 16px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 600 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 400 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 32px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 21px;
  --mat-bottom-sheet-container-text-size: 16px;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 15px;
  --mat-legacy-button-toggle-label-text-size: 11px;
  --mat-legacy-button-toggle-label-text-weight: 600;
  --mat-standard-button-toggle-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 15px;
  --mat-standard-button-toggle-label-text-size: 11px;
  --mat-standard-button-toggle-label-text-weight: 600;
}

html {
  --mat-datepicker-calendar-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 18px;
  --mat-datepicker-calendar-body-label-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 18px;
  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 600;
}

html {
  --mat-expansion-header-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-expansion-container-text-line-height: 21px;
  --mat-expansion-container-text-size: 16px;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 16px;
  --mat-grid-list-tile-header-secondary-text-size: 11px;
  --mat-grid-list-tile-footer-primary-text-size: 16px;
  --mat-grid-list-tile-footer-secondary-text-size: 11px;
}

html {
  --mat-stepper-container-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-stepper-header-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-stepper-header-label-text-size: 16px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 11px;
  --mat-stepper-header-selected-state-label-text-size: 11px;
  --mat-stepper-header-selected-state-label-text-weight: 600;
}

html {
  --mat-toolbar-title-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-toolbar-title-text-line-height: 21px;
  --mat-toolbar-title-text-size: 16px;
  --mat-toolbar-title-text-weight: 600;
}

html {
  --mat-tree-node-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-tree-node-text-size: 16px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-option-label-text-line-height: 15px;
  --mat-option-label-text-size: 11px;
  --mat-option-label-text-weight: 600;
}

html {
  --mat-optgroup-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-optgroup-label-text-line-height: 15px;
  --mat-optgroup-label-text-size: 11px;
  --mat-optgroup-label-text-weight: 600;
}

html {
  --mat-card-title-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-card-title-text-line-height: 21px;
  --mat-card-title-text-size: 16px;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 16px;
  --mat-card-subtitle-text-weight: 400;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 11px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
}

html {
  --mdc-filled-text-field-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 11px;
  --mdc-filled-text-field-label-text-weight: 600;
  --mdc-outlined-text-field-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 11px;
  --mdc-outlined-text-field-label-text-weight: 600;
  --mat-form-field-container-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-form-field-container-text-line-height: 15px;
  --mat-form-field-container-text-size: 11px;
  --mat-form-field-container-text-weight: 600;
  --mat-form-field-outlined-label-text-populated-size: 11px;
  --mat-form-field-subscript-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-form-field-subscript-text-line-height: 15px;
  --mat-form-field-subscript-text-size: 11px;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-select-trigger-text-line-height: 15px;
  --mat-select-trigger-text-size: 11px;
  --mat-select-trigger-text-weight: 600;
}

html {
  --mdc-dialog-subhead-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-dialog-subhead-line-height: 21px;
  --mdc-dialog-subhead-size: 16px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-dialog-supporting-text-line-height: 15px;
  --mdc-dialog-supporting-text-size: 11px;
  --mdc-dialog-supporting-text-weight: 600;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-chip-label-text-line-height: 21px;
  --mdc-chip-label-text-size: 16px;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-switch-label-text-line-height: 21px;
  --mat-switch-label-text-size: 16px;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-radio-label-text-line-height: 21px;
  --mat-radio-label-text-size: 16px;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-slider-label-label-text-size: 16px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-weight: 400;
}

html {
  --mat-menu-item-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-menu-item-label-text-size: 11px;
  --mat-menu-item-label-text-line-height: 15px;
  --mat-menu-item-label-text-weight: 600;
}

html {
  --mdc-list-list-item-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-list-list-item-label-text-line-height: 15px;
  --mdc-list-list-item-label-text-size: 11px;
  --mdc-list-list-item-label-text-weight: 600;
  --mdc-list-list-item-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 21px;
  --mdc-list-list-item-supporting-text-size: 16px;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 15px;
  --mdc-list-list-item-trailing-supporting-text-size: 11px;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}

html {
  --mat-paginator-container-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-paginator-container-text-line-height: 15px;
  --mat-paginator-container-text-size: 11px;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 11px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-tab-header-label-text-size: 18px;
  --mat-tab-header-label-text-line-height: 24px;
  --mat-tab-header-label-text-weight: 600;
}

html {
  --mat-checkbox-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-checkbox-label-text-line-height: 21px;
  --mat-checkbox-label-text-size: 16px;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-text-button-label-text-size: 18px;
  --mdc-text-button-label-text-weight: 600;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-filled-button-label-text-size: 18px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-protected-button-label-text-size: 18px;
  --mdc-protected-button-label-text-weight: 600;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-outlined-button-label-text-size: 18px;
  --mdc-outlined-button-label-text-weight: 600;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-extended-fab-label-text-size: 18px;
  --mdc-extended-fab-label-text-weight: 600;
}

html {
  --mdc-snackbar-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 21px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 16px;
  --mat-table-header-headline-weight: 400;
  --mat-table-row-item-label-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 21px;
  --mat-table-row-item-label-text-size: 16px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-footer-supporting-text-font: Segoe UI, Lucida Sans, Arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 21px;
  --mat-table-footer-supporting-text-size: 16px;
  --mat-table-footer-supporting-text-weight: 400;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html body {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body [portal-display-1] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body [portal-display-2] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body [portal-display-3] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 48px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body [portal-display-4] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 32px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body h1,
html body [portal-headline] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 20px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin-bottom: 16px;
}
html body h2,
html body [portal-title] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin-bottom: 12px;
}
html body h3,
html body [portal-subheading-1] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin-bottom: 8px;
}
html body h4,
html body [portal-subheading-2] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin-bottom: 8px;
}
html body [portal-body-2] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body [portal-caption] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body .mat-mdc-form-field,
html body input {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  font-family: Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body portal-button,
html body [portal-button] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 18px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body portal-button .portal-button-icon-wrapper *,
html body [portal-button] .portal-button-icon-wrapper * {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 18px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body portal-image .default-image mat-icon {
  font-size: 24px;
}
html body portal-profile-site [title-part] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body portal-wenetwork-oauth-provider p,
html body portal-wenetwork-oauth-provider a {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
html body .mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}
html body portal-field[required].ng-dirty .mdc-text-field__input::placeholder {
  color: red !important;
}
html body mat-form-field.mat-form-field-disabled .mat-mdc-form-field-infix {
  border-bottom: 1px rgba(0, 0, 0, 0.38) dotted;
}
html body mat-form-field.mat-form-field-disabled .mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}
html body .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: transparent !important;
  transition: none !important;
}
html body .mat-mdc-form-field-infix {
  min-height: 50px !important;
  padding-top: 24px !important;
  padding-bottom: 4px !important;
}
html body portal-field input.mat-mdc-input-element {
  position: absolute;
}
html body .mat-mdc-form-field-hint-wrapper,
html body .mat-mdc-form-field-error-wrapper {
  display: block;
  padding-left: 0;
}
html body .mdc-text-field {
  padding: 0 !important;
}
html body label[matformfieldfloatinglabel][ng-reflect-floating=true] {
  top: 27px !important;
}
html body label[matformfieldfloatinglabel][ng-reflect-floating=false] {
  top: 35px !important;
}
html body .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
  transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1), color 400ms cubic-bezier(0.25, 0.8, 0.25, 1), top 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
html body .mat-mdc-form-field {
  /* stylelint-disable */
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}
html body * {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
}
@media (min-width: 1024px) {
  html body {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 16px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body [portal-display-1] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body [portal-display-2] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body [portal-display-3] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 48px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body [portal-display-4] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 32px / 1.25 Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body h1,
  html body [portal-headline] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 20px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
    margin-bottom: 16px;
  }
  html body h2,
  html body [portal-title] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
    margin-bottom: 12px;
  }
  html body h3,
  html body [portal-subheading-1] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
    margin-bottom: 8px;
  }
  html body h4,
  html body [portal-subheading-2] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
    margin-bottom: 8px;
  }
  html body [portal-body-2] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body [portal-caption] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body .mat-mdc-form-field,
  html body input {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    font-size: inherit;
    font-weight: 400;
    line-height: 1.5;
    font-family: Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body portal-button,
  html body [portal-button] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 18px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body portal-button .portal-button-icon-wrapper *,
  html body [portal-button] .portal-button-icon-wrapper * {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 18px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body portal-image .default-image mat-icon {
    font-size: 24px;
  }
  html body portal-profile-site [title-part] {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body portal-wenetwork-oauth-provider p,
  html body portal-wenetwork-oauth-provider a {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
  html body * {
    /* If any of the values are set to `inherit`, we can not use the shorthand
     so we fall back to passing in the individual properties. */
    /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
     that we need to use interpolation for `font-size/line-height` in order to prevent
     Sass from dividing the two values. */
    font: 400 16px / 24px Segoe UI, Lucida Sans, Arial, sans-serif;
  }
}

portal-button,
[portal-button] {
  background-color: transparent;
  border: none;
  color: var(--wen-primary-color);
  padding: 3px;
  margin: 3px;
}
portal-button[inlined]:hover, portal-button[inlined]:focus,
[portal-button][inlined]:hover,
[portal-button][inlined]:focus {
  color: white;
  background-color: #f96778;
}
portal-button[flat],
[portal-button][flat] {
  background-color: var(--wen-primary-color);
  color: white;
}
portal-button[outlined],
[portal-button][outlined] {
  color: var(--wen-primary-color);
  border: solid 2px var(--wen-primary-color);
}
portal-button[secondary],
[portal-button][secondary] {
  color: #8abd0c;
}
portal-button[secondary][inlined]:hover, portal-button[secondary][inlined]:focus,
[portal-button][secondary][inlined]:hover,
[portal-button][secondary][inlined]:focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bedb79;
}
portal-button[secondary][flat],
[portal-button][secondary][flat] {
  background-color: #8abd0c;
  color: white;
}
portal-button[secondary][outlined],
[portal-button][secondary][outlined] {
  color: #8abd0c;
  border: solid 2px #8abd0c;
}
portal-button:not([disabled]):not([inlined]):hover, portal-button:not([disabled]):not([inlined]):focus,
[portal-button]:not([disabled]):not([inlined]):hover,
[portal-button]:not([disabled]):not([inlined]):focus {
  filter: brightness(120%);
}
portal-button > portal-icon,
[portal-button] > portal-icon {
  width: 100%;
  height: 100%;
}

portal-toolbar {
  background-size: cover;
}
portal-toolbar div.portal-toolbar {
  align-content: end;
}
portal-toolbar div.portal-toolbar > div.headline > * {
  padding: 0;
  margin: 0;
  user-select: none;
  align-self: end;
  justify-self: center;
}
portal-toolbar:not([secondary]) {
  background-color: var(--wen-primary-color);
  padding: 28px 32px;
}
portal-toolbar:not([secondary]) div.portal-toolbar > div.left > *,
portal-toolbar:not([secondary]) div.portal-toolbar > div.right > * {
  color: white;
  background-color: transparent;
}
portal-toolbar:not([secondary]) div.portal-toolbar > div.headline > * {
  color: white;
}
portal-toolbar[secondary] {
  background-color: white;
  padding: 12px 16px;
  border-bottom: solid #8abd0c 4px;
}
portal-toolbar[secondary] div.portal-toolbar > div.headline > * {
  color: #8abd0c;
}

portal-icon {
  color: inherit;
}
portal-icon div.portal-icon {
  color: inherit;
}
portal-icon div.portal-icon > svg {
  color: inherit;
}
portal-icon div.portal-icon > svg > use {
  color: inherit;
}
portal-icon[primary] {
  color: var(--wen-primary-color);
}
portal-icon[secondary] {
  color: #8abd0c;
}

portal-card,
portal-cards > div.portal-cards > div.portal-card-wrapper {
  min-width: 180px;
  margin: 8px;
  transition: 0.15s ease all;
  transform: translate3d(0, 0, 0);
}
portal-card[portal-card-flat] .portal-card,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: none;
  cursor: pointer;
}
portal-card[portal-card-flat] .portal-card .portal-card-content,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card .portal-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
portal-card[portal-card-flat] .portal-card .portal-card-image-container,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card .portal-card-image-container {
  height: 250px;
}
portal-card[portal-card-flat] .portal-card .portal-card-inserted,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card .portal-card-inserted {
  display: flex;
  flex-direction: column;
  height: 100%;
}
portal-card[portal-card-flat] .portal-card .content,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card .content {
  flex: 1 0 0px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
}
portal-card[portal-card-flat] .portal-card h2,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card h2 {
  margin: 0;
  padding-bottom: 10px;
  color: var(--wen-primary-color);
}
portal-card[portal-card-flat] .portal-card a,
portal-cards > div.portal-cards > div.portal-card-wrapper[portal-card-flat] .portal-card a {
  color: var(--wen-primary-color);
  margin-top: 6px;
}
portal-card > div.portal-card,
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: minmax(min-content, 100%);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.06);
  height: 100%;
  background-color: #fff;
  position: relative;
}
portal-card > div.portal-card > div.portal-card-image-container,
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-image-container {
  display: grid;
  grid-template: auto/auto;
  position: relative;
  background-color: var(--wen-primary-color);
}
portal-card > div.portal-card > div.portal-card-image-container > [portal-card-image],
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-image-container > [portal-card-image] {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 100%;
  min-height: 190px;
  object-fit: cover;
}
portal-card > div.portal-card > div.portal-card-image-container > div.portal-card-icon,
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-image-container > div.portal-card-icon {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: start;
  align-self: end;
}
portal-card > div.portal-card > div.portal-card-image-container > div.portal-card-icon [portal-card-icon],
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-image-container > div.portal-card-icon [portal-card-icon] {
  width: 80px;
  height: 80px;
  padding: 16px;
}
portal-card > div.portal-card > div.portal-card-content,
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-content {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5px;
  padding: 16px;
}
portal-card > div.portal-card > div.portal-card-content > div.portal-card-inserted,
portal-cards > div.portal-cards > div.portal-card-wrapper > div.portal-card > div.portal-card-content > div.portal-card-inserted {
  max-height: 300px;
  overflow: hidden;
}
portal-card[big] > div.portal-card,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card {
  display: grid;
  grid-template: 1fr/1fr;
}
portal-card[big] > div.portal-card > div.portal-card-image-container,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-image-container {
  grid-area: 1/1/2/2;
  z-index: 0;
}
portal-card[big] > div.portal-card > div.portal-card-content,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content {
  display: grid;
  grid-template: max-content max-content max-content max-content 1fr/1fr;
  grid-area: 1/1/2/2;
  z-index: 10;
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.6));
}
portal-card[big] > div.portal-card > div.portal-card-content > [portal-card-title],
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > [portal-card-title] {
  grid-area: 1/1/2/2;
}
portal-card[big] > div.portal-card > div.portal-card-content > [portal-card-subtitle],
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > [portal-card-subtitle] {
  grid-area: 2/1/3/2;
}
portal-card[big] > div.portal-card > div.portal-card-content > [portal-card-footer],
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > [portal-card-footer] {
  grid-area: 3/1/4/2;
}
portal-card[big] > div.portal-card > div.portal-card-content > div.portal-card-inserted,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > div.portal-card-inserted {
  grid-area: 4/1/5/2;
}
portal-card[big] > div.portal-card > div.portal-card-content > div.portal-card-icon,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > div.portal-card-icon {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: start;
  align-self: end;
}
portal-card[big] > div.portal-card > div.portal-card-content > div.portal-card-icon [portal-card-icon],
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > div.portal-card-icon [portal-card-icon] {
  width: 48px;
  height: 48px;
  padding: 0;
}
portal-card[big] > div.portal-card > div.portal-card-content > div.portal-card-icon,
portal-cards > div.portal-cards > div.portal-card-wrapper[big] > div.portal-card > div.portal-card-content > div.portal-card-icon {
  grid-area: 5/1/6/2;
}
portal-card[secondary],
portal-cards > div.portal-cards > div.portal-card-wrapper[secondary] {
  background-color: white;
}
portal-card[secondary] > div.portal-card > div.portal-card-image-container,
portal-cards > div.portal-cards > div.portal-card-wrapper[secondary] > div.portal-card > div.portal-card-image-container {
  background-color: #8abd0c;
}
portal-card[aria-disabled=true] > div.portal-card::after,
portal-cards > div.portal-cards > div.portal-card-wrapper[aria-disabled=true] > div.portal-card::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  /* We set this to 101% because the box-shadow would not fit otherwise */
  height: 100%;
  cursor: not-allowed;
  opacity: 0.1;
  background-color: black;
}

portal-cards > div.portal-cards {
  padding: 3px;
}
portal-cards > div.portal-cards portal-card {
  cursor: pointer;
}
portal-cards > div.portal-cards portal-card:not([aria-disabled=true]):hover, portal-cards > div.portal-cards portal-card:not([aria-disabled=true]):focus, portal-cards > div.portal-cards portal-card:not([aria-disabled=true])[aria-selected=true] {
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.115);
  transform: scale(1.02);
}

portal-rating {
  --portal-rating-size: 15px;
  --portal-rating-gap: 5px;
}
portal-rating div.rating-root div.star {
  width: var(--portal-rating-size);
  height: var(--portal-rating-size);
  padding: calc(var(--portal-rating-gap) / 2);
  box-sizing: content-box;
}
portal-rating div.rating-root div.star portal-icon {
  height: inherit;
  width: inherit;
}
portal-rating div.rating-root div.star:first-child {
  padding-left: 0;
}
portal-rating div.rating-root div.star:last-child {
  padding-right: 0;
}
portal-rating[primary] {
  color: var(--wen-primary-color);
}
portal-rating[primary] div.rating-root {
  color: inherit;
}
portal-rating[primary] div.rating-root div.star {
  color: inherit;
}
portal-rating[primary] div.rating-root div.star portal-icon {
  color: inherit;
}
portal-rating[secondary] {
  color: #8abd0c;
}
portal-rating[secondary] div.rating-root {
  color: inherit;
}
portal-rating[secondary] div.rating-root div.star {
  color: inherit;
}
portal-rating[secondary] div.rating-root div.star portal-icon {
  color: inherit;
}

portal-tag div.portal-tag div.tag-name [portal-tag-name] {
  color: #999999;
}

portal-slideshow div.slideshow-root div.slideshow-thumbnails div.slideshow-thumbnail {
  opacity: 0.5;
  transition: 0.3s ease all;
}
portal-slideshow div.slideshow-root div.slideshow-thumbnails div.slideshow-thumbnail.selected {
  opacity: 1;
}
portal-slideshow div.slideshow-root div.slideshow-mainimage {
  margin-bottom: 10px;
}
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-caption {
  color: white;
  margin-bottom: 10px;
}
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-leftarrow,
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-rightarrow {
  padding: 0 10px;
  transition: 0.2s ease all;
}
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-leftarrow:hover,
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-rightarrow:hover {
  background: var(--portal-slideshow-navigation-backround-hover);
}
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-leftarrow:hover portal-icon,
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-rightarrow:hover portal-icon {
  color: #999999;
}
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-leftarrow portal-icon,
portal-slideshow div.slideshow-root div.slideshow-mainimage div.slideshow-mainimage-container div.slideshow-rightarrow portal-icon {
  height: 20px;
  width: 20px;
  color: white;
}
portal-slideshow div.slideshow-root div.slideshow-dots div.slideshow-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: #999999;
  opacity: 0.5;
  transition: 0.3s ease all;
}
portal-slideshow div.slideshow-root div.slideshow-dots div.slideshow-dot.selected {
  opacity: 1;
}

portal-chip {
  background-color: #999;
  color: #fff;
  padding: 7px 12px;
}
portal-chip[primary] {
  background-color: var(--wen-primary-color);
  color: white;
}
portal-chip[secondary] {
  background-color: #8abd0c;
  color: white;
}
portal-chip div.chip-root {
  color: inherit;
}
portal-chip div.chip-root div.chip-text {
  color: inherit;
}
portal-chip div.chip-root div.chip-text * {
  color: inherit;
  font-size: inherit;
}
portal-chip div.chip-root div.chip-button {
  color: inherit;
}
portal-chip div.chip-root div.chip-button portal-button {
  color: inherit;
}

.portal-progress-curtain {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  opacity: 0.5;
  background-color: #fff;
}

portal-upload {
  /* stylelint-disable-next-line declaration-no-important, max-line-length */
  border-color: rgba(0, 0, 0, 0.42) !important;
}

.portal-file-drop-target::after {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: var(--wen-primary-color) !important;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed;
  background-color: rgba(255, 255, 255, 0.85);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em;
  z-index: 100;
}

portal-image .default-image {
  background: #fff;
  border-color: #fff;
}
portal-image .default-image mat-icon {
  color: #9f0012;
}
portal-image[secondary] .default-image mat-icon {
  color: #618508;
}

portal-profile-site .info-wrapper {
  background: #fff;
}
portal-profile-site [title-part] {
  color: #9f0012;
}
portal-profile-site[secondary] [title-part] {
  color: #618508;
}

portal-button-toggle[inlined] mat-button-toggle {
  color: #f96778;
}
portal-button-toggle[inlined] .mat-button-toggle-checked {
  color: white;
  background-color: #f96778;
}
portal-button-toggle[flat] mat-button-toggle {
  color: var(--wen-primary-color);
}
portal-button-toggle[flat] .mat-button-toggle-checked {
  color: white;
  background-color: var(--wen-primary-color);
}
portal-button-toggle[outlined] mat-button-toggle {
  color: var(--wen-primary-color);
  border: solid 2px var(--wen-primary-color) !important;
}
portal-button-toggle[outlined] .mat-button-toggle-checked {
  color: white;
  background-color: var(--wen-primary-color);
  border: solid 1px var(--wen-primary-color) !important;
}
portal-button-toggle:not([disabled]):not([inlined]):hover, portal-button-toggle:not([disabled]):not([inlined]):focus {
  filter: brightness(110%);
}
portal-button-toggle[secondary] {
  color: #8abd0c;
}
portal-button-toggle[secondary][inlined] mat-button-toggle {
  color: #bedb79;
}
portal-button-toggle[secondary][inlined] .mat-button-toggle-checked {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bedb79;
}
portal-button-toggle[secondary][flat] mat-button-toggle {
  color: #8abd0c;
}
portal-button-toggle[secondary][flat] .mat-button-toggle-checked {
  color: rgba(0, 0, 0, 0.87);
  background-color: #8abd0c;
}
portal-button-toggle[secondary][outlined] mat-button-toggle {
  color: #8abd0c;
  border: solid 2px #8abd0c !important;
}
portal-button-toggle[secondary][outlined] .mat-button-toggle-checked {
  color: rgba(0, 0, 0, 0.87);
  background-color: #8abd0c;
  border: solid 1px #8abd0c !important;
}
portal-button-toggle[secondary]:not([disabled]):not([inlined]):hover, portal-button-toggle[secondary]:not([disabled]):not([inlined]):focus {
  filter: brightness(110%);
}

portal-list-element[with-hover] {
  position: relative;
}
portal-list-element[with-hover]:hover::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #8abd0c;
  z-index: -1;
  opacity: 0.15;
}

.mat-mdc-checkbox-indeterminate.mat-accent .mdc-checkbox__background,
.mat-mdc-checkbox-checked.mat-accent .mdc__checkbox-background {
  background-color: transparent;
}

mat-checkbox .mdc-checkbox__background {
  background-color: transparent !important;
}
mat-checkbox .mdc-checkbox__checkmark {
  color: var(--wen-primary-color) !important;
}
mat-checkbox .mdc-checkbox__background {
  border-color: var(--wen-primary-color) !important;
}
mat-checkbox a.portal-primary-link {
  color: var(--wen-primary-color);
}
mat-checkbox a.portal-primary-link {
  text-decoration: none;
}
mat-checkbox a.portal-primary-link:hover {
  text-decoration: none;
  cursor: pointer;
}
mat-checkbox a.portal-accent-link {
  color: #8abd0c;
}
mat-checkbox a.portal-accent-link {
  text-decoration: none;
}
mat-checkbox a.portal-accent-link:hover {
  text-decoration: none;
  cursor: pointer;
}

portal-image-upload {
  background: #fff;
}
portal-image-upload.portal-image-upload {
  border-color: #999999;
}
portal-image-upload .remove-button {
  color: var(--wen-primary-color);
}

.portal-file-drop-target::after {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: var(--wen-primary-color) !important;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed;
  background-color: rgba(255, 255, 255, 0.85);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em;
  z-index: 100;
}

portal-tag-input-dialog header {
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}
portal-tag-input-dialog .item {
  border-bottom: 1px solid #d9d9d9;
}
portal-tag-input-dialog .item::after {
  background-color: #8abd0c;
}
portal-tag-input-dialog .item:hover::after {
  opacity: 0.08;
}
portal-tag-input-dialog .item.selected::after {
  opacity: 0.17;
}
portal-tag-input-dialog .search mat-icon,
portal-tag-input-dialog .item portal-icon {
  color: #777777;
}

portal-popper {
  background: #fff;
}

portal-wenetwork-oauth-provider p {
  color: black;
}

.expandable-container-wrapper {
  background: #fff;
}

portal-parts mat-tab-group.top .index {
  background-color: rgba(0, 0, 0, 0.12);
}
portal-parts mat-tab-group.top .mdc-tab--active .index {
  background-color: #f96778;
  color: white;
}
portal-parts mat-tab-group.side {
  flex-direction: row;
}
portal-parts mat-tab-group.side .index {
  background-color: rgba(0, 0, 0, 0.12);
}
portal-parts mat-tab-group.side .mat-mdc-tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
portal-parts mat-tab-group.side .mdc-tab--active {
  background-color: #f96778;
  border-bottom: none;
}
portal-parts mat-tab-group.side .mdc-tab--active .mdc-tab__text-label {
  transition-delay: 0ms;
  color: white !important;
}
portal-parts mat-tab-group.side .mdc-tab--active .index {
  background-color: var(--wen-primary-color);
  color: white;
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--wen-primary-color);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #8abd0c;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--wen-primary-color);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--wen-primary-color);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #8abd0c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #8abd0c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #8abd0c;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #8abd0c;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--wen-primary-color);
  --mdc-linear-progress-track-color: var(--wen-primary-color);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #8abd0c;
  --mdc-linear-progress-track-color: rgba(138, 189, 12, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: var(--wen-primary-color);
  --mdc-filled-text-field-focus-active-indicator-color: var(--wen-primary-color);
  --mdc-filled-text-field-focus-label-text-color: var(--wen-primary-color);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: var(--wen-primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--wen-primary-color);
  --mdc-outlined-text-field-focus-label-text-color: var(--wen-primary-color);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: var(--wen-primary-color);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #8abd0c;
  --mdc-filled-text-field-focus-active-indicator-color: #8abd0c;
  --mdc-filled-text-field-focus-label-text-color: rgba(138, 189, 12, 0.87);
  --mdc-outlined-text-field-caret-color: #8abd0c;
  --mdc-outlined-text-field-focus-outline-color: #8abd0c;
  --mdc-outlined-text-field-focus-label-text-color: rgba(138, 189, 12, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(138, 189, 12, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--wen-primary-color);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(138, 189, 12, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--wen-primary-color);
  --mdc-chip-elevated-selected-container-color: var(--wen-primary-color);
  --mdc-chip-elevated-disabled-container-color: var(--wen-primary-color);
  --mdc-chip-flat-disabled-selected-container-color: var(--wen-primary-color);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #8abd0c;
  --mdc-chip-elevated-selected-container-color: #8abd0c;
  --mdc-chip-elevated-disabled-container-color: #8abd0c;
  --mdc-chip-flat-disabled-selected-container-color: #8abd0c;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #ea2138;
  --mdc-switch-selected-handle-color: #ea2138;
  --mdc-switch-selected-hover-state-layer-color: #ea2138;
  --mdc-switch-selected-pressed-state-layer-color: #ea2138;
  --mdc-switch-selected-focus-handle-color: #b50015;
  --mdc-switch-selected-hover-handle-color: #b50015;
  --mdc-switch-selected-pressed-handle-color: #b50015;
  --mdc-switch-selected-focus-track-color: #ff97a3;
  --mdc-switch-selected-hover-track-color: #ff97a3;
  --mdc-switch-selected-pressed-track-color: #ff97a3;
  --mdc-switch-selected-track-color: #ff97a3;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #9bc730;
  --mdc-switch-selected-handle-color: #9bc730;
  --mdc-switch-selected-hover-state-layer-color: #9bc730;
  --mdc-switch-selected-pressed-state-layer-color: #9bc730;
  --mdc-switch-selected-focus-handle-color: #6e970a;
  --mdc-switch-selected-hover-handle-color: #6e970a;
  --mdc-switch-selected-pressed-handle-color: #6e970a;
  --mdc-switch-selected-focus-track-color: #cee49c;
  --mdc-switch-selected-hover-track-color: #cee49c;
  --mdc-switch-selected-pressed-track-color: #cee49c;
  --mdc-switch-selected-track-color: #cee49c;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-hover-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-pressed-icon-color: var(--wen-primary-color);
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--wen-primary-color);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #8abd0c;
  --mdc-radio-selected-hover-icon-color: #8abd0c;
  --mdc-radio-selected-icon-color: #8abd0c;
  --mdc-radio-selected-pressed-icon-color: #8abd0c;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #8abd0c;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: var(--wen-primary-color);
  --mdc-slider-focus-handle-color: var(--wen-primary-color);
  --mdc-slider-hover-handle-color: var(--wen-primary-color);
  --mdc-slider-active-track-color: var(--wen-primary-color);
  --mdc-slider-inactive-track-color: var(--wen-primary-color);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--wen-primary-color);
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: var(--wen-primary-color);
  --mat-slider-hover-state-layer-color: rgba(var(--wen-primary-color), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--wen-primary-color), 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #8abd0c;
  --mdc-slider-focus-handle-color: #8abd0c;
  --mdc-slider-hover-handle-color: #8abd0c;
  --mdc-slider-active-track-color: #8abd0c;
  --mdc-slider-inactive-track-color: #8abd0c;
  --mdc-slider-with-tick-marks-inactive-container-color: #8abd0c;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #8abd0c;
  --mat-slider-hover-state-layer-color: rgba(138, 189, 12, 0.05);
  --mat-slider-focus-state-layer-color: rgba(138, 189, 12, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-hover-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-icon-color: var(--wen-primary-color);
  --mdc-radio-selected-pressed-icon-color: var(--wen-primary-color);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #8abd0c;
  --mdc-radio-selected-hover-icon-color: #8abd0c;
  --mdc-radio-selected-icon-color: #8abd0c;
  --mdc-radio-selected-pressed-icon-color: #8abd0c;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--wen-primary-color);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #8abd0c;
  --mdc-checkbox-selected-hover-icon-color: #8abd0c;
  --mdc-checkbox-selected-icon-color: #8abd0c;
  --mdc-checkbox-selected-pressed-icon-color: #8abd0c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #8abd0c;
  --mdc-checkbox-selected-hover-state-layer-color: #8abd0c;
  --mdc-checkbox-selected-pressed-state-layer-color: #8abd0c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: var(--wen-primary-color);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--wen-primary-color);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--wen-primary-color);
  --mat-tab-header-active-ripple-color: var(--wen-primary-color);
  --mat-tab-header-inactive-ripple-color: var(--wen-primary-color);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--wen-primary-color);
  --mat-tab-header-active-hover-label-text-color: var(--wen-primary-color);
  --mat-tab-header-active-focus-indicator-color: var(--wen-primary-color);
  --mat-tab-header-active-hover-indicator-color: var(--wen-primary-color);
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #8abd0c;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #8abd0c;
  --mat-tab-header-active-ripple-color: #8abd0c;
  --mat-tab-header-inactive-ripple-color: #8abd0c;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #8abd0c;
  --mat-tab-header-active-hover-label-text-color: #8abd0c;
  --mat-tab-header-active-focus-indicator-color: #8abd0c;
  --mat-tab-header-active-hover-indicator-color: #8abd0c;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--wen-primary-color);
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #8abd0c;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #8abd0c;
  --mdc-checkbox-selected-hover-icon-color: #8abd0c;
  --mdc-checkbox-selected-icon-color: #8abd0c;
  --mdc-checkbox-selected-pressed-icon-color: #8abd0c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #8abd0c;
  --mdc-checkbox-selected-hover-state-layer-color: #8abd0c;
  --mdc-checkbox-selected-pressed-state-layer-color: #8abd0c;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-icon-color: var(--wen-primary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--wen-primary-color);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--wen-primary-color);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--wen-primary-color);
  --mat-text-button-state-layer-color: var(--wen-primary-color);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #8abd0c;
  --mat-text-button-state-layer-color: #8abd0c;
  --mat-text-button-ripple-color: rgba(138, 189, 12, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--wen-primary-color);
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #8abd0c;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--wen-primary-color);
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #8abd0c;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--wen-primary-color);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--wen-primary-color);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #8abd0c;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #8abd0c;
  --mat-outlined-button-ripple-color: rgba(138, 189, 12, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--wen-primary-color);
  --mat-icon-button-state-layer-color: var(--wen-primary-color);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #8abd0c;
  --mat-icon-button-state-layer-color: #8abd0c;
  --mat-icon-button-ripple-color: rgba(138, 189, 12, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: var(--wen-primary-color);
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #8abd0c;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--wen-primary-color);
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #8abd0c;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #8abd0c;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: var(--wen-primary-color);
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #8abd0c;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: var(--wen-primary-color);
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #8abd0c;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--wen-primary-color);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--wen-primary-color);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--wen-primary-color);
  --mat-datepicker-toggle-active-state-icon-color: var(--wen-primary-color);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--wen-primary-color), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #8abd0c;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(138, 189, 12, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(138, 189, 12, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(138, 189, 12, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(138, 189, 12, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #8abd0c;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--wen-primary-color);
}
.mat-icon.mat-accent {
  --mat-icon-color: #8abd0c;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--wen-primary-color);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--wen-primary-color);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--wen-primary-color);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #8abd0c;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #8abd0c;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #8abd0c;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--wen-primary-color);
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #8abd0c;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

.portal-drag-overlay-pane > :first-child {
  width: inherit;
  height: inherit;
}
.portal-drag-overlay-pane > :first-child * {
  pointer-events: none;
}

portal-drag-overlay {
  border-color: var(--wen-primary-color);
  border-style: dashed;
  border-width: 2px;
  background-color: rgba(255, 255, 255, 0.85);
  background-image: url(../../../assets/svg/upload-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3em;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("/fonts/MaterialIcons-Regular.woff") format("woff"), url("/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.wen-nav-bar .wen-nav-bar-content {
  background: #f7f7f7;
  height: 50px;
}
.wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link:not(.wen-nav-bar-item-link-active) mat-icon, .wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link:not(.wen-nav-bar-item-link-active) .wen-nav-bar-item-label {
  color: #959595;
}
.wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link:not(.wen-nav-bar-item-link-active) mat-icon svg, .wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link:not(.wen-nav-bar-item-link-active) .wen-nav-bar-item-label svg {
  fill: #959595 !important;
}
.wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link.wen-nav-bar-item-link-active mat-icon, .wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link.wen-nav-bar-item-link-active .wen-nav-bar-item-label {
  color: var(--wen-primary-color);
}
.wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link.wen-nav-bar-item-link-active mat-icon svg, .wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-link.wen-nav-bar-item-link-active .wen-nav-bar-item-label svg {
  fill: var(--wen-primary-color) !important;
}
.wen-nav-bar .wen-nav-bar-item .wen-nav-bar-item-label {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-new-message-creation-wrapper {
  background-color: #f7f7f7;
}

.wen-channel-list-empty-list-title {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-channel-list-empty-list-text {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-channel-list-item-description {
  color: #5d5d5d;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-rounded-button:not(.wen-rounded-button-transparent) .wen-rounded-button-button {
  background-color: var(--wen-primary-color);
  color: white;
}
.wen-rounded-button.wen-rounded-button-transparent .wen-rounded-button-button {
  background-color: transparent;
  color: var(--wen-primary-color);
}
.wen-rounded-button.wen-rounded-button-border .wen-rounded-button-button {
  border: 1px solid var(--wen-primary-color);
}
.wen-rounded-button:not(.wen-rounded-button-border) .wen-rounded-button-button {
  border: none;
}
.wen-rounded-button.wen-rounded-button-background .wen-rounded-button-button {
  background-color: transparent;
  color: #767676;
}
.wen-rounded-button.wen-rounded-button-disabled .wen-rounded-button-button {
  background-color: #cccccc;
}

.wen-notification-count:not(.wen-notification-count-disabled) .wen-notification-count-content {
  background: var(--wen-primary-color);
}
.wen-notification-count.wen-notification-count-disabled .wen-notification-count-content {
  background: #767676;
}

.wen-search-bar .wen-search-bar-cancel-search {
  color: var(--wen-primary-color);
}

.wen-search-bar-search-field {
  background: var(--wen-search-bar-background-color);
}

.wen-user-profile-view {
  background-color: #f7f7f7;
}
.wen-user-profile-view .wen-user-view-profile-entry-primary {
  color: var(--wen-primary-color);
}
.wen-user-profile-view .wen-user-view-profile-entry-primary [menuEntryTitle] {
  color: var(--wen-primary-color);
}
.wen-user-profile-view .wen-user-profile-header-container .wen-user-profile-header-username {
  color: #333333;
}
.wen-user-profile-view .wen-user-profile-scroller {
  background-color: #f7f7f7;
}

.wen-list-item [wen-list-item-title], .wen-list-item .wen-list-item-title {
  color: #333333;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
.wen-list-item [wen-list-item-description], .wen-list-item .wen-list-item-description {
  color: #5d5d5d;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}
.wen-list-item [wen-list-item-subtitle], .wen-list-item .wen-list-item-subtitle {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-message-box-content-wrapper {
  background: white;
  color: #333333;
}

wen-app-header:not(.wen-app-secondary-header) .wen-app-header-wrapper {
  background-color: var(--wen-header-background-color);
}
wen-app-header:not(.wen-app-secondary-header) .wen-app-header-wrapper .wen-app-header-title-text {
  color: var(--wen-header-text-color);
}

mat-expansion-panel-header {
  background-color: white;
}

.mat-expansion-panel-body {
  background-color: white;
}

.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header:hover {
  background: white !important;
}

wen-embed-notification wen-horizontal-card wen-markdown-text p, wen-embed-notification wen-horizontal-card wen-markdown-text strong {
  font-size: 13px;
  line-height: 17px;
}

.wen-embed-forward [portal-caption] {
  color: #5d5d5d;
}

.wen-event-detail-layout {
  background-color: #f7f7f7;
}

.wen-box {
  background-color: white;
}
.wen-box .expandable-container-wrapper {
  background-color: white;
}

wen-email-settings {
  background-color: #f7f7f7;
}
wen-email-settings .wen-profile-email-setting-page-entry-groups .wen-profile-email-setting-page-entry-group {
  background-color: #f7f7f7;
}
wen-email-settings .wen-profile-email-setting-page-entry-groups .wen-profile-email-setting-page-entry-group .wen-interact-button-menu-style {
  background-color: white;
}
wen-email-settings .wen-profile-email-setting-page-entry-groups .wen-profile-email-setting-page-entry-group .wen-interact-button-menu-style span {
  color: #333333;
}
wen-email-settings .wen-profile-email-setting-page-entry-groups .wen-profile-email-setting-page-entry-group .wen-profile-email-setting-page-email-info {
  background-color: white;
}

.wen-profile-read-scroller {
  background-color: #f7f7f7;
}

.wen-user-credentials-container {
  height: 100%;
  background-color: #f7f7f7;
}

.wen-user-credentials-menu-entries, .wen-user-credentials-interact-button, .wen-credential-card-wrapper {
  background-color: white;
}

.wen-profile-additional-information-box {
  background-color: white;
}
.wen-profile-additional-information-box .expandable-container-wrapper {
  background-color: white;
}

.wen-channel-detail {
  background-color: #f7f7f7;
}
.wen-channel-detail .wen-detail-header-cover-wrapper {
  background-color: white;
}
.wen-channel-detail .wen-channel-detail-content-group:not(.wen-channel-detail-submenus) {
  background-color: white;
}
.wen-channel-detail .wen-channel-detail-content-group .wen-channel-detail-info-text {
  color: black;
}
.wen-channel-detail .wen-detail-header-subheader {
  background-color: white;
}

.wen-mobile-header {
  background-color: var(--wen-header-background-color);
  border-bottom: 1px solid #f7f7f7;
}

.wen-app-detail-content-group {
  background-color: white;
}

.wen-user-app-list {
  background-color: white;
}

.wen-channel-restriction-wrapper {
  background-color: white;
}

wen-user-event-list {
  background-color: white;
}

.wen-coming-soon {
  background-color: white;
}

wen-channel-featured-view {
  background-color: #f2f2f2;
}
wen-channel-featured-view wen-navigate-to-channel-button {
  background-color: white;
}
wen-channel-featured-view wen-navigate-to-channel-button .wen-navigate-to-channel-button-text-description {
  color: #333333 !important;
}

.wen-admin-list-container {
  background-color: white;
}

.wen-create-welcome {
  background-color: white;
}

.wen-discover-channel-list {
  background-color: white;
}

.wen-home-container {
  background-color: white;
}
.wen-home-container .wen-home-featured wen-message-preview-card {
  background-color: white;
}
.wen-home-container .wen-home-text {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  color: #333333;
}

portal-image-upload {
  background-color: white;
}

.fade {
  background: linear-gradient(hsla(0, 100%, 100%, 0.1) 10%, hsla(0, 100%, 100%, 0.9) 70%) !important;
}

.button {
  color: #5d5d5d !important;
  background-color: white !important;
}

wen-discovery-event-list {
  background-color: white;
}

wen-discover-app-list {
  background-color: white;
}

wen-user-channel-list {
  background-color: white;
}

h1.wen-empty-list-title {
  color: #5d5d5d !important;
}

div.wen-empty-list-description {
  color: #5d5d5d !important;
}

.wen-detail-header {
  background-color: white;
}
.wen-detail-header [wen-detail-header-title] {
  color: #333333;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
.wen-detail-header [wen-detail-header-description] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
.wen-detail-header .wen-detail-header-subheader {
  background-color: white;
}
.wen-detail-header img.wen-detail-header-square-image-content {
  background-color: white;
}

.wen-mobile-primary-outlet {
  background-color: #f7f7f7;
}

.wen-mobile-navigation-bar {
  border-top: 1px solid #f7f7f7;
}

.wen-onboarding-wrapper {
  background-color: white;
}

img.wen-lazy-load {
  opacity: 0;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 200ms, -webkit-filter 200ms;
}
img.wen-lazy-load.lazyloading {
  transition: opacity 200ms;
}
img.wen-lazy-load.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  opacity: 1;
}

.wen-image-crop-dialog-panel {
  /* stylelint-disable-next-line custom-property-pattern */
  --cropper-overlay-color: wen-components.$var-white2;
  max-height: 640px !important;
  min-height: 400px !important;
  height: auto !important;
}
.wen-image-crop-dialog-panel mat-dialog-container {
  height: auto !important;
  padding: 0;
  border-radius: 0;
}
@media (orientation: portrait) and (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
  .wen-image-crop-dialog-panel {
    min-height: 100vh !important;
    min-width: 100vw !important;
  }
}

.wen-form,
.wen-form-v2 {
  --wen-form-field-spacing-size: 1em;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: 100%;
}
.wen-form .wen-form-group,
.wen-form-v2 .wen-form-group {
  flex: 0 1 auto;
  padding: 1em;
}
.wen-form .wen-form-group.wen-single-group,
.wen-form-v2 .wen-form-group.wen-single-group {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.wen-form .wen-form-group.wen-single-group:has(.wen-form-field-scroller),
.wen-form-v2 .wen-form-group.wen-single-group:has(.wen-form-field-scroller) {
  padding-right: 0;
}
.wen-form .wen-form-group.wen-single-group .wen-form-group-hint,
.wen-form-v2 .wen-form-group.wen-single-group .wen-form-group-hint {
  margin-top: 0.5em;
}
.wen-form .wen-form-group:not(:last-child),
.wen-form-v2 .wen-form-group:not(:last-child) {
  margin-bottom: 6px;
}
.wen-form .wen-form-group mat-form-field.mat-form-field-invalid .wen-form-field-label,
.wen-form-v2 .wen-form-group mat-form-field.mat-form-field-invalid .wen-form-field-label {
  color: unset;
}
.wen-form .wen-form-group .wen-form-field-scroller,
.wen-form-v2 .wen-form-group .wen-form-field-scroller {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 0 1 auto;
  padding-right: 0.5em;
}
.wen-form .wen-form-group .wen-form-field-wrapper,
.wen-form-v2 .wen-form-group .wen-form-field-wrapper {
  display: flex;
  flex-direction: column;
}
.wen-form .wen-form-group .wen-form-field-wrapper:not(:last-of-type),
.wen-form-v2 .wen-form-group .wen-form-field-wrapper:not(:last-of-type) {
  margin-bottom: var(--wen-form-field-spacing-size);
}
.wen-form .wen-form-group .wen-form-field-wrapper.wen-image-upload,
.wen-form-v2 .wen-form-group .wen-form-field-wrapper.wen-image-upload {
  align-items: center;
  padding: 0 24px;
}
.wen-form .wen-form-group .wen-form-field-wrapper .wen-form-field-label,
.wen-form-v2 .wen-form-group .wen-form-field-wrapper .wen-form-field-label {
  margin-bottom: 0.5em;
}
.wen-form .wen-form-group .wen-form-field-wrapper .wen-form-field,
.wen-form-v2 .wen-form-group .wen-form-field-wrapper .wen-form-field {
  width: 100%;
  display: flex;
}
.wen-form .wen-form-group .wen-form-field-wrapper .wen-toggle-field,
.wen-form-v2 .wen-form-group .wen-form-field-wrapper .wen-toggle-field {
  justify-content: space-between;
}
.wen-form .wen-form-group .wen-form-field-wrapper .wen-toggle-field:not(:last-of-type),
.wen-form-v2 .wen-form-group .wen-form-field-wrapper .wen-toggle-field:not(:last-of-type) {
  margin-bottom: 0.5em;
}
.wen-form .wen-form-group-hint,
.wen-form-v2 .wen-form-group-hint {
  margin-bottom: 0;
  text-align: center;
}
.wen-form .wen-form-group-hint wen-icon,
.wen-form-v2 .wen-form-group-hint wen-icon {
  display: inline-block;
}
.wen-form .wen-form-actions,
.wen-form-v2 .wen-form-actions {
  margin-top: 0.75em;
}

.wen-form .custom-form-group:not(:last-child) {
  margin-bottom: 6px;
}

html body .mdc-text-field {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

html body .mat-mdc-form-field-infix {
  min-height: 56px !important;
  padding-bottom: 8px !important;
}

html body label[matformfieldfloatinglabel][ng-reflect-floating=false] {
  top: 28px !important;
}

html body mat-form-field.mat-form-field-disabled .mat-mdc-text-field-wrapper {
  background-color: #f7f7f7 !important;
}

html body mat-form-field.mat-form-field-disabled .mat-mdc-form-field-infix {
  border-bottom: none;
}

mat-form-field .mat-mdc-form-field-focus-overlay,
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
mat-form-field label {
  font-weight: 400 !important;
  pointer-events: none !important;
}

.wen-form-v2 {
  --wen-form-field-background-color: #eeeeee;
}

html body .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--wen-form-field-background-color, #f7f7f7) !important;
}
html body .wen-form-field-white .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
html body mat-form-field .mat-mdc-text-field-wrapper {
  background-color: #f7f7f7 !important;
}
html body mat-form-field.wen-form-field-white .mat-mdc-text-field-wrapper {
  background-color: white !important;
}

.wen-form-v2 {
  --wen-form-background-color: #f7f7f7;
  --wen-form-group-background-color: #f7f7f7;
  --wen-form-group-border-color: #f7f7f7;
}

.wen-form, .wen-form-v2 {
  background-color: var(--wen-form-background-color, #f7f7f7);
}
.wen-form .wen-form-group,
.wen-form .wen-form-actions, .wen-form-v2 .wen-form-group,
.wen-form-v2 .wen-form-actions {
  border-bottom: 1px solid var(--wen-form-group-border-color, #d1d1d1);
  background-color: var(--wen-form-group-background-color, white);
}
.wen-form .wen-form-group:not(:first-of-type),
.wen-form .wen-form-actions:not(:first-of-type), .wen-form-v2 .wen-form-group:not(:first-of-type),
.wen-form-v2 .wen-form-actions:not(:first-of-type) {
  border-top: 1px solid var(--wen-form-group-border-color, #d1d1d1);
}
.wen-form .wen-form-group .wen-form-field-label,
.wen-form .wen-form-actions .wen-form-field-label, .wen-form-v2 .wen-form-group .wen-form-field-label,
.wen-form-v2 .wen-form-actions .wen-form-field-label {
  color: #767676;
}
.wen-form .wen-form-group .wen-form-field-wrapper,
.wen-form .wen-form-actions .wen-form-field-wrapper, .wen-form-v2 .wen-form-group .wen-form-field-wrapper,
.wen-form-v2 .wen-form-actions .wen-form-field-wrapper {
  background-color: var(--wen-form-group-background-color, white);
}
.wen-form .wen-form-group-hint, .wen-form-v2 .wen-form-group-hint {
  color: #767676;
}

wen-time-picker {
  background-color: white;
}

ngx-mat-datepicker-content.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1411764706), 0 1px 10px rgba(0, 0, 0, 0.1215686275);
  background-color: white;
  color: #333333;
}
ngx-mat-datepicker-content .mat-calendar-body-label {
  font-size: 18px;
  font-weight: 600;
  color: #959595;
}
ngx-mat-datepicker-content .mat-datepicker-toggle, ngx-mat-datepicker-content .mat-calendar-next-button, ngx-mat-datepicker-content .mat-calendar-previous-button {
  color: #767676;
}
ngx-mat-datepicker-content .mat-calendar-table-header {
  color: #767676;
}
ngx-mat-datepicker-content .mat-calendar-table-header-divider:after {
  background-color: #e5e5e5;
}
ngx-mat-datepicker-content .time-container {
  margin-top: 10px;
}
ngx-mat-datepicker-content .mat-datepicker-actions {
  padding: 16px 8px;
  justify-content: space-around !important;
}
ngx-mat-datepicker-content .mat-calendar-body-cell-content {
  border: none;
  color: #333333;
}
ngx-mat-datepicker-content .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--wen-primary-color);
  color: white;
}
ngx-mat-datepicker-content .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--wen-primary-color);
  color: white;
}
ngx-mat-datepicker-content .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #cccccc;
}
ngx-mat-datepicker-content .mat-calendar-body-disabled {
  cursor: default !important;
}

.ngx-mat-timepicker .mdc-line-ripple, .ngx-mat-timepicker .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.ngx-mat-timepicker input {
  font-size: 16px !important;
}
.ngx-mat-timepicker .mat-mdc-form-field-infix {
  padding-top: 4px !important;
}

wen-user-chat-list {
  background-color: white;
}

.wen-channel-scheduled-messages-content {
  background-color: #f2f2f2;
}

.wen-channel-schedules-edit-wrapper {
  background-color: #f7f7f7;
}

.wen-chat-scheduled-messages-content {
  background-color: #f2f2f2;
}

.wen-chat-schedules-edit-wrapper {
  background-color: #f7f7f7;
}

.wen-chat-view-bottom-controls {
  background-color: #f7f7f7;
}

.wen-user-list-item .wen-user-list-item-title {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}

wen-channel-subscriber-list .wen-user-role {
  display: block;
  color: #5d5d5d;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}

wen-chat-detail-user-list .wen-user-role {
  color: #5d5d5d;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}
wen-chat-detail-user-list .disabled {
  color: #cccccc;
}
wen-chat-detail-user-list .wen-chat-detail-add-member-counter:not(.disabled) {
  color: #5d5d5d;
}

.wen-explore-header-label {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 20px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-chat-creation-bottom-controls {
  background-color: #f7f7f7;
}

wen-create-welcome-markdown wen-markdown-text p {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}

.wen-weather-notification-time-wrapper {
  color: #333333;
}

[with-colon]:after {
  content: ": ";
}

[in-brackets]:before {
  content: "(";
}
[in-brackets]:after {
  content: ")";
}

[with-pipe-before]:before {
  content: "| ";
}

.wen-christmas-overlay {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.wen-filter-dialog .mat-mdc-dialog-container, .wen-selector-dialog .mat-mdc-dialog-container {
  height: auto !important;
  border-radius: 12px !important;
  margin: 0px !important;
  padding: 10px !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.wen-lightbox-dialog .mat-mdc-dialog-container {
  /* stylelint-disable */
  --mdc-dialog-container-color: transparent;
  --mdc-dialog-container-shape: 0;
  /* stylelint-enable */
}

.pswp {
  /* stylelint-disable */
  --pswp-root-z-index: 0;
  /* stylelint-enable */
}
.pswp img {
  cursor: auto;
}
.pswp img.pswp__img--placeholder {
  z-index: 1;
}
.pswp .pswp_error_img {
  width: 100%;
  height: 100%;
}
.pswp .pswp__video-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wen-registration-dialog .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 0;
}

.cdk-overlay-container .wen-confirmation-dialog {
  max-width: 600px !important;
  margin: 0px !important;
}
.cdk-overlay-container .wen-confirmation-dialog .mat-mdc-dialog-container {
  border-radius: 12px !important;
  margin: 0px !important;
  padding: 10px !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.wen-message-box-popper {
  box-shadow: 0 2px 6px rgba(51, 51, 51, 0.6) !important;
}

.popper-backdrop {
  background-color: rgba(51, 51, 51, 0.6);
}

.cdk-overlay-pane {
  z-index: 1001;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(51, 51, 51, 0.6);
}

.cdk-overlay-container {
  position: absolute;
  z-index: auto;
}
.cdk-overlay-container .wen-lightbox-dialog {
  max-width: 100vw !important;
  width: 100%;
  height: 100%;
}
.cdk-overlay-container .wen-lightbox-dialog mat-dialog-container {
  padding: 0;
  background: transparent;
}

.cdk-global-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  z-index: 1001;
}

.wen-context-menu-panel {
  max-height: 80%;
  width: 50%;
}

@media (max-width: 1024px) {
  .wen-context-menu-panel {
    width: 100%;
  }
}
html body [portal-title] {
  margin-bottom: 6px;
}

html body * {
  font-weight: unset;
  font-style: unset;
}
html body * em {
  font-style: italic;
}
html body * strong {
  font-weight: bold;
}

button[portal-button] {
  filter: none !important;
  outline: none;
}

portal-button[inlined]:hover, portal-button[inlined]:focus,
[portal-button][inlined]:hover, [portal-button][inlined]:focus {
  background: transparent !important;
  color: var(--wen-primary-color);
  cursor: pointer !important;
}

portal-image img {
  padding: 0 !important;
}

button {
  cursor: pointer !important;
}

button[portal-button][inlined][disabled] {
  color: #959595;
}

*[tabindex] {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

input, textarea {
  user-select: auto !important;
}

b {
  font: inherit !important;
  font-weight: 600 !important;
}

a {
  color: #1467ba;
  text-decoration: none;
  cursor: pointer;
}
a:focus {
  outline: none;
}

portal-progress-bar {
  position: relative;
}
portal-progress-bar mat-progress-bar {
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
}

.wen-highlighted {
  color: #959595;
}

mat-checkbox .mdc-checkbox__background {
  border-color: #959595 !important;
}
mat-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  border-color: var(--wen-primary-color) !important;
}

mat-form-field.wen-hide-underline .mdc-line-ripple {
  display: none;
}
mat-form-field .mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-96%) scale(0.75);
}
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-line-ripple:before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-line-ripple::after {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

/* stylelint-disable custom-property-pattern */
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-fab-color: white !important;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: var(--wen-primary-color);
  --mdc-switch-selected-handle-color: var(--wen-primary-color);
  --mdc-switch-selected-hover-state-layer-color: var(--wen-primary-color);
  --mdc-switch-selected-pressed-state-layer-color: var(--wen-primary-color);
  --mdc-switch-selected-focus-handle-color: var(--wen-primary-color);
  --mdc-switch-selected-hover-handle-color: var(--wen-primary-color);
  --mdc-switch-selected-pressed-handle-color: var(--wen-primary-color);
  --mdc-switch-selected-focus-track-color: var(--wen-primary-color);
  --mdc-switch-selected-hover-track-color: var(--wen-primary-color);
  --mdc-switch-selected-pressed-track-color: var(--wen-primary-color);
  --mdc-switch-selected-track-color: var(--wen-primary-color);
  /* stylelint-enable custom-property-pattern */
}
.mat-mdc-slide-toggle.mat-primary .mdc-switch__track {
  opacity: 0.5;
}
.mat-mdc-slide-toggle.mat-primary .mdc-switch__ripple {
  display: none;
}

html body [wen-header-big] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 24px / 32px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin-bottom: 16px;
}

html body h5, html body [wen-header-huge] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 32px / 38px Segoe UI, Lucida Sans, Arial, sans-serif;
  margin: 0 0 8px 0;
}

.mat-mdc-snack-bar-container {
  margin: 12px;
  background: white;
  /* stylelint-disable-next-line custom-property-pattern */
  --mdc-snackbar-container-color: $default-background;
  padding: 0 !important;
}
.mat-mdc-snack-bar-container.wen-snackbar-notification {
  border-radius: 12px;
  margin: 24px;
  color: #333333;
  /* stylelint-disable-next-line custom-property-pattern */
  --mdc-snackbar-supporting-text-weight: 600;
  /* stylelint-disable-next-line custom-property-pattern */
  --mdc-snackbar-supporting-text-color: variables.$grey8;
}
.mat-mdc-snack-bar-container.wen-snackbar-notification .mat-mdc-snack-bar-label {
  padding-left: 0;
}
.mat-mdc-snack-bar-container.wen-snackbar-notification .mdc-snackbar__surface {
  border-radius: 12px;
}

[wen-primary-color] {
  color: var(--wen-primary-color);
}

[wen-primary-background-color] {
  background-color: var(--wen-primary-color);
}

.wen-action-menu-content {
  display: block;
  min-width: 150px;
  background-color: white;
  border-radius: 4px 4px 4px 4px;
}
.wen-action-menu-content button {
  color: #333333;
  width: 100%;
  border: none;
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  padding: 8px !important;
}
.wen-action-menu-content button[portal-title] {
  margin-bottom: 0;
}
.wen-action-menu-content button wen-icon {
  color: #767676;
  margin-right: 12px;
}

cdk-virtual-scroll-viewport {
  height: 100%;
}
cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

@media (max-width: 1024px) {
  ngx-mat-datetime-content {
    margin: -24px;
  }
}
wen-markdown-text table th {
  font-weight: bold;
  border: 1px solid #cccccc;
  border-bottom: 2px solid black;
}
wen-markdown-text table td {
  border: 1px solid #cccccc;
}
wen-markdown-text blockquote {
  border-left: 1px solid #333333;
}
wen-markdown-text span h1 {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 24px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  line-height: normal;
  margin: 8px 0;
}
wen-markdown-text span h2 {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 20px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  line-height: normal;
}
wen-markdown-text span h3 {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
wen-markdown-text span h4 {
  font-size: 13px;
  font-weight: 600;
}
wen-markdown-text span h5 {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}
wen-markdown-text span h6 {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 11px / 15px Segoe UI, Lucida Sans, Arial, sans-serif;
}
wen-markdown-text span h2, wen-markdown-text span h3, wen-markdown-text span h4, wen-markdown-text span h5, wen-markdown-text span h6 {
  margin: 4px 0;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  box-shadow: none !important;
}

.mdc-switch__icons {
  display: none;
}

mat-checkbox.cursor-initial .mdc-checkbox {
  cursor: initial;
}

.mdc-checkbox__ripple {
  display: none;
}

mat-checkbox:not(.borderless-check).mat-mdc-checkbox-checked .mdc-checkbox__background svg {
  transform: scale(0.8);
}

.wen-confirmation-dialog-wrapper {
  background-color: white;
}
.wen-confirmation-dialog-wrapper button.wen-confirmation-dialog-wide-action {
  background-color: transparent;
  border: none;
  border-top: 1px solid #b1b1b1;
  color: #b1b1b1;
}
.wen-confirmation-dialog-wrapper button.wen-confirmation-dialog-wide-action:not([disabled]) {
  color: var(--wen-primary-color);
}

.wen-separator-background .wen-separator-arrow {
  border-right-color: #cccccc !important;
}
.wen-separator-background .wen-separator-text {
  color: white;
  background-color: #cccccc;
}
.wen-separator-background hr.wen-separator-line {
  border-bottom-color: #cccccc !important;
}
.wen-separator-background .wen-separator-date {
  background: #cccccc;
}

.wen-message-box-popper {
  background-color: white;
}

.wen-poll-creation [portal-button] {
  font-weight: 400;
}

mat-checkbox.borderless-check .mdc-checkbox__ripple {
  display: none;
}
mat-checkbox.borderless-check .mdc-checkbox__background {
  border-color: transparent !important;
}
mat-checkbox.borderless-check.mat-mdc-checkbox-checked .mdc-checkbox__background svg {
  transform: scale(1.5);
}
mat-checkbox.borderless-check.mat-mdc-checkbox-checked .mdc-checkbox__background svg path {
  stroke: var(--wen-primary-color) !important;
}

wen-selector-dialog {
  background-color: white;
}

.wen-horizontal-card .wen-horizontal-card-info-container h2, .wen-horizontal-card .wen-horizontal-card-info-container h3 {
  color: #333333;
}

wen-button.wen-button-variant-icon-text {
  background-color: transparent;
}
wen-button.wen-button-variant-icon-text wen-icon {
  color: #767676;
}
wen-button.wen-button-variant-icon-text .wen-button-text-content {
  color: #333333;
}
wen-button.wen-button-variant-icon-text.wen-button-disabled {
  background-color: transparent;
}
wen-button.wen-button-variant-icon-text.wen-button-disabled wen-icon {
  color: #cccccc;
}
wen-button.wen-button-variant-icon-text.wen-button-disabled .wen-button-text-content {
  color: #cccccc;
}
wen-button.wen-button-variant-icon {
  background-color: transparent;
}
wen-button.wen-button-variant-icon wen-icon {
  color: #333333;
}
wen-button.wen-button-variant-icon.wen-button-disabled {
  background-color: transparent;
}
wen-button.wen-button-variant-icon.wen-button-disabled wen-icon {
  color: #cccccc;
}
wen-button.wen-button-variant-icon.wen-button-disabled .wen-button-text-content {
  color: #cccccc;
}
wen-button.wen-button-variant-icon-primary {
  background-color: var(--wen-primary-color);
}
wen-button.wen-button-variant-icon-primary wen-icon {
  color: white;
}
wen-button.wen-button-variant-icon-primary.wen-button-disabled {
  background-color: #cccccc;
}
wen-button.wen-button-variant-primary {
  background-color: var(--wen-primary-color);
}
wen-button.wen-button-variant-primary wen-icon {
  color: white;
}
wen-button.wen-button-variant-primary .wen-button-text-content {
  color: white;
}
wen-button.wen-button-variant-primary.wen-button-disabled {
  background-color: #cccccc;
}
wen-button.wen-button-variant-primary.wen-button-disabled wen-icon {
  color: white;
}
wen-button.wen-button-variant-primary.wen-button-disabled .wen-button-text-content {
  color: white;
}
wen-button.wen-button-variant-secondary {
  background-color: transparent;
  border: 1px solid var(--wen-primary-color);
}
wen-button.wen-button-variant-secondary wen-icon {
  color: var(--wen-primary-color);
}
wen-button.wen-button-variant-secondary .wen-button-text-content {
  color: var(--wen-primary-color);
}
wen-button.wen-button-variant-secondary.wen-button-disabled {
  background-color: transparent;
}
wen-button.wen-button-variant-secondary.wen-button-disabled wen-icon {
  color: #cccccc;
}
wen-button.wen-button-variant-secondary.wen-button-disabled .wen-button-text-content {
  color: #cccccc;
}
wen-button.wen-button-variant-primary-background {
  background-color: transparent;
}
wen-button.wen-button-variant-primary-background wen-icon {
  color: white;
}
wen-button.wen-button-variant-primary-background .wen-button-text-content {
  color: var(--wen-primary-color);
}
wen-button.wen-button-variant-primary-background.wen-button-disabled {
  background-color: #cccccc;
}
wen-button.wen-button-variant-primary-background.wen-button-disabled wen-icon {
  color: white;
}
wen-button.wen-button-variant-primary-background.wen-button-disabled .wen-button-text-content {
  color: white;
}

wen-location-preview.wen-location-preview-variant-normal {
  background-color: #eeeeee;
  color: #333333;
  border-radius: 20px;
}
wen-location-preview.wen-location-preview-variant-normal .wen-location-preview-icon,
wen-location-preview.wen-location-preview-variant-normal .wen-location-preview-text {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
}
wen-location-preview.wen-location-preview-variant-normal .wen-location-preview-icon {
  margin-right: 12px;
}
wen-location-preview.wen-location-preview-variant-normal .wen-location-preview-dismiss {
  color: var(--wen-primary-color);
}
wen-location-preview.wen-location-preview-variant-small {
  background-color: #f7f7f7;
  color: #444444;
  border-radius: 4px;
}
wen-location-preview.wen-location-preview-variant-small .wen-location-preview-icon,
wen-location-preview.wen-location-preview-variant-small .wen-location-preview-text {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
  font-weight: 600;
}
wen-location-preview.wen-location-preview-variant-small .wen-location-preview-icon {
  margin-right: 8px;
}
wen-location-preview.wen-location-preview-variant-small .wen-location-preview-dismiss {
  color: var(--wen-primary-color);
}

.wen-media-preview-details {
  color: #5d5d5d;
}

.video-player vg-controls vg-time-display span {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
}

wen-icon[color=primary] svg {
  fill: var(--wen-primary-color);
}
wen-icon.wen-icon-variant-secondary-inlined {
  background-color: transparent;
  color: #333333;
}
wen-icon.wen-icon-variant-primary-flat {
  background-color: var(--wen-primary-color);
  color: white;
}
wen-icon.wen-icon-variant-secondary-flat {
  background-color: #eeeeee;
  color: #767676;
}
wen-icon.wen-icon-variant-assistive-inlined {
  background-color: white;
  color: #767676;
}
wen-icon:not(.wen-icon-colorful) mat-icon.wen-svg-icon path {
  fill: unset;
}

wen-section .wen-section-header [section-header-title] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  color: #333333;
}
wen-section .wen-section-header [section-header-action] {
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  color: #5d5d5d;
  white-space: nowrap;
}

wen-card [card-title] {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 600 16px / 21px Segoe UI, Lucida Sans, Arial, sans-serif;
  color: #333333;
}
wen-card [card-description] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* If any of the values are set to `inherit`, we can not use the shorthand
   so we fall back to passing in the individual properties. */
  /* Otherwise use the shorthand `font`, because it is the least amount of bytes. Note
   that we need to use interpolation for `font-size/line-height` in order to prevent
   Sass from dividing the two values. */
  font: 400 13px / 17px Segoe UI, Lucida Sans, Arial, sans-serif;
  color: #959595;
}

.wen-scroll-to-button-notification-count {
  background: var(--wen-primary-color);
}

.wen-scroll-to-button button {
  background-color: #f7f7f7;
}
.wen-scroll-to-button button wen-icon {
  color: #767676;
}

.wen-menu-entry:not(:last-of-type, .wen-menu-entry-flat) {
  border-bottom: 2px solid #f7f7f7;
}

.wen-menu-entry-layout-group:has(*) {
  overflow: hidden;
  border-radius: 10px;
  margin-top: 18px;
}
.wen-menu-entry-layout-group:has(*) .wen-menu-entry:not(:last-of-type, .wen-menu-entry-flat) {
  margin-bottom: 2px;
  border-bottom: 0px !important;
}

@media (min-width: 1024px) {
  :root {
    --wen-search-bar-text-color: #8e8e93 !important;
    --wen-search-bar-background-color: #e9e9e9 !important;
  }
}
:root wen-desktop-sub-header {
  --wen-header-command-color-back: var(--wen-primary-color) !important;
}
:root wen-mobile-header {
  border-bottom: 1px solid var(--wen-header-background-color);
}
:root wen-desktop-header {
  border-bottom: 1px solid var(--wen-header-background-color);
}

.wen-menu-entry[entry-id=CONTACT_SUPPORT] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=MANIFEST] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=PRIVACY] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=IMPRESSUM] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=DATA_PROTECTION] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=NETIQUETTE] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=LICENSES] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=TERMS_OF_USE] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=HELP] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

.wen-menu-entry[entry-id=HOTLINE] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

wen-help-view .wen-menu-entry[entry-id=MARKDOWN] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

wen-hotline-view .wen-menu-entry[entry-id=HOTLINE] .wen-menu-entry-icon mat-icon {
  background-color: #3ca1ad !important;
}

html, body {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Segoe UI, Lucida Sans, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}