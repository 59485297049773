@use "sass:map";
@use '@portal/ui-kit';
@use './variables';
@use "@portal/wen-widget";
@use "@portal/wen-components";

/* Color config */
$color-primary-map: map.merge(
  $map1: ui-kit.$portal-red,
  $map2: (
    700: var(--wen-primary-color),
  ),
);
$color-secondary-map: ui-kit.$portal-light-green;
$app-primary: ui-kit.define-palette($color-primary-map);
$app-accent: ui-kit.define-palette($color-secondary-map);

/* Font config */
$typography: ui-kit.define-typography-config(
  /* Font style */ $font-family: #{$font},
  /* Display 1 */ $display-1: ui-kit.define-typography-level(24px, 21px, 600),
  /* Display 2 */ $display-2: ui-kit.define-typography-level(24px, 21px, 400),
  /* H1 */ $headline: ui-kit.define-typography-level(20px, 21px, 600),
  /* H2 */ $title: ui-kit.define-typography-level(16px, 21px, 600),
  /* H3 */ $subheading-1: ui-kit.define-typography-level(13px, 17px, 400),
  /* H4 */ $subheading-2: ui-kit.define-typography-level(11px, 15px, 600),
  /* Body */ $body-1: ui-kit.define-typography-level(16px, 24px, 400),
  /* Subtitle */ $body-2: ui-kit.define-typography-level(16px, 21px, 400),
  /* Buttons */ $button: ui-kit.define-typography-level(18px, 24px, 600),
  /* Caption*/ $caption: ui-kit.define-typography-level(11px, 15px, 400)
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include ui-kit.core($typography, $typography);`
@include ui-kit.core($typography, $typography);

$wen-light-theme: wen-widget.define-light-theme($app-primary, $app-accent);
@include ui-kit.all-component-themes($wen-light-theme);
@include wen-widget.all-themes($wen-light-theme, $typography);
@include wen-components.all-themes($wen-light-theme, $typography);

@include wen-widget.only-desktop-style() {
  :root {
    --wen-search-bar-text-color: #8e8e93 !important;
    --wen-search-bar-background-color: #e9e9e9 !important;
  }
}

:root {
  wen-desktop-sub-header {
    --wen-header-command-color-back: var(--wen-primary-color) !important;
  }
  wen-mobile-header {
    border-bottom: 1px solid var(--wen-header-background-color);
  }
  wen-desktop-header {
    border-bottom: 1px solid var(--wen-header-background-color);
  }
}

@include wen-widget.color-user-profile-icon("CONTACT_SUPPORT", #3ca1ad);
@include wen-widget.color-user-profile-icon("MANIFEST", #3ca1ad);
@include wen-widget.color-user-profile-icon("PRIVACY", #3ca1ad);
@include wen-widget.color-user-profile-icon("IMPRESSUM", #3ca1ad);
@include wen-widget.color-user-profile-icon("DATA_PROTECTION", #3ca1ad);
@include wen-widget.color-user-profile-icon("NETIQUETTE", #3ca1ad);
@include wen-widget.color-user-profile-icon("LICENSES", #3ca1ad);
@include wen-widget.color-user-profile-icon("TERMS_OF_USE", #3ca1ad);
@include wen-widget.color-user-profile-icon("HELP", #3ca1ad);
@include wen-widget.color-user-profile-icon("HOTLINE", #3ca1ad);
wen-help-view {
  @include wen-widget.color-user-profile-icon("MARKDOWN", #3ca1ad);
}
wen-hotline-view {
  @include wen-widget.color-user-profile-icon("HOTLINE", #3ca1ad);
}
